.TitleText {
  text-align: center;
  margin-bottom: 2px;
}

.SameRow {
  display: inline-flex;
}

.ant-dropdown-placement-bottomCenter {
  overflow: auto;
  height: 300px;
}
