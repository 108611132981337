.SearchBar {
  margin-left: "2.5%";
  margin-right: "2.5%";
  float:left;
  line-height: 5em;
}

.SameRow {
  display: inline-flex;
}

.ant-dropdown-menu  {
  border-radius: 0px;
}